<template>
	<div class="grid p-fluid">
        <Toast/>
        <div class="col-12">
			<div class="card" :style="{'background-color':this.cardBackgroundColor}">
                <div style="width: 100%; display: flex;">
                    <h2 style="font-size: large;">Guía de Remisión - {{ this.$store.getters.defaultActivity.name }}</h2>
                </div>
                <div class="grid formgrid" style="margin-top: 0px;" v-show="this.sale.id !== null">
                    <div class="col-12">
                        <div class="card">
                            <h5 style="margin-bottom: 25px;"><b>Factura {{ (this.sale.id !== null) ? " N: " + this.sale.serialNumber : this.guide.saleSerialNumber }}</b></h5>
                            <div class="p-fluid formgrid grid">
                                <div class="field col-12 md:col-6">
                                    <div style="display: flex;">
                                        <label style="font-weight: bold; text-align: left;">Cliente:</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ (this.sale.customer.id !== null) ? this.sale.customer.name : "CONSUMIDOR FINAL" }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Céd/RUC/Pasaporte:</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ (this.sale.customer.id !== null) ? this.sale.customer.identification : "9999999999" }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Dirección</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ (this.sale.customer.id !== null) ? this.sale.customer.address : "-" }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Correo</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ (this.sale.customer.email !== null) ? this.sale.customer.email : "-" }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Teléfono</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ (this.sale.customer.id !== null) ? this.sale.customer.phone : "9999999999" }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Fecha Emisión</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ (this.sale.id !== null) ? this.sale.createdAt.toLocaleDateString("es-ES") : "" }}</label>
                                    </div>
                                </div>

                                <div class="field col-12 md:col-6 saleInformationContainer">
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Subtotal 0%</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ this.sale.sub0.toFixed(2) }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Subtotal 12%</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ this.sale.sub12.toFixed(2) }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Subtotal sin impuestos</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ (this.sale.sub0 + this.sale.sub12).toFixed(2) }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">IVA (12%)</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ (this.sale.sub12 * 0.12).toFixed(2) }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Descuento</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ this.sale.discount.toFixed(2) }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Total</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ this.sale.total.toFixed(2) }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid formgrid" style="margin-top: 20px;">
                    <div class="col-12">
                        <div class="card">
                            <h5><b>Producto(s)</b></h5>
                            <Button icon="pi pi-plus" v-if="this.guide.id === null" class="p-button-rounded p-button-success mr-2 mb-2" style="position: absolute; top: 15px; right: 20px;" @click="displayProductDialog"/>
                            <DataTable :value="this.guide.items" editMode="cell" :scrollable="true" scrollHeight="400px" scrollDirection="both" class="mt-3 editable-cells-table">
                                <Column field="amount" header="Cantidad" :style="{width: '25%','min-width':'50px'}">
                                    <template #editor="{data, field}" v-if="this.guide.id === null">
                                        <InputText v-model="data[field]" type="number" autofocus :disabled="this.guide.id !== null" @change="onItemChanged(data)" />
                                    </template>
                                </Column>
                                <Column field="description" header="Descripción" :style="{width: '70%','min-width':'100px'}">
                                    <template #editor="{data, field}" v-if="this.guide.id === null">
                                        <InputText v-model="data[field]" type="text" autofocus :disabled="this.guide.id !== null" @change="onItemChanged(data)" />
                                    </template>
                                </Column>
                                <Column :exportable="false" style="width: 50px;">
                                    <template #body="item" v-if="this.guide.id === null">
                                        <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text mr-2 mb-2"  @click="onItemDeleted(item)"/>
                                    </template>
                                </Column>
                            </DataTable>
                            <h6 class="p-invalid" v-if="this.productError" style="color: red;">Ingrese al menos un producto</h6>
                        </div>
                    </div>
                </div>

                <div class="grid formgrid" style="margin-top: 20px;">
                    <div class="col-12">
                        <div class="card">
                            <h5><b>Destino(s)</b></h5>
                            <Button icon="pi pi-plus" v-if="this.guide.id === null" class="p-button-rounded p-button-success mr-2 mb-2" style="position: absolute; top: 15px; right: 20px;" @click="displayDestinationDialog"/>
                            <DataTable :value="this.guide.destinations" editMode="cell" :scrollable="true" scrollHeight="400px" scrollDirection="both" class="mt-3 editable-cells-table">
                                <Column field="receiverIdentification" header="Céd/RUC/Pas" :style="{width: '20%','min-width':'40px'}"></Column>
                                <Column field="receiverName" header="Rázon Social" :style="{width: '20%','min-width':'40px'}"></Column>
                                <Column field="address" header="Dirección" :style="{width: '20%','min-width':'40px'}"></Column>
                                <Column field="reason" header="Motivo" :style="{width: '30%','min-width':'40px'}"></Column>
                                <Column :exportable="false" style="width: 5% ;min-width: 30px;">
                                    <template #body="item" v-if="this.guide.id === null">
                                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-button-text mr-2 mb-2"  @click="onEditDestination(item)"/>
                                    </template>
                                </Column>
                                <Column :exportable="false" style="width: 5% ;min-width: 30px;">
                                    <template #body="item" v-if="this.guide.id === null">
                                        <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text mr-2 mb-2"  @click="onDestintationDeleted(item)"/>
                                    </template>
                                </Column>
                            </DataTable>
                            <h6 class="p-invalid" v-if="this.destinationError" style="color: red;">Ingrese al menos un destino</h6>
                        </div>
                    </div>
                </div>
                
                <div class="grid formgrid" style="margin-top: 20px;">
                    <div class="col-12">
                        <div class="card">
                            <h5 style="margin-bottom: 25px;"><b>Datos del transportista</b></h5>
                            <div class="p-fluid formgrid grid">
                                <div class="field col-12 md:col-12">
                                    <div class="grid formgrid" style="width: 100%;">
                                        <div class="col-12 mb-2 lg:col-4 lg:mb-0">
                                            <div style="display: flex">
                                                <label for="courrierName">Nombre del transportista</label><br>
                                                <small style="color: gray; margin-left: auto;">Busque por nombre</small>
                                            </div>
                                            <AutoComplete autofocus placeholder="Nombres del transportista" v-model="this.courrier" :disabled="this.guide.id !== null" optionLabel="name" field="name" :suggestions="this.courrierSuggestions" @complete="searchCourrier($event)">
                                                <template #option="slotProps">
                                                    <div>{{ slotProps.option.name + " - " + slotProps.option.id }}</div>
                                                </template>
                                            </AutoComplete>  
                                            <small class="p-invalid" v-if="this.courrierNameError">Ingrese el nombre del transportista</small>
                                        </div>
                                        <div class="col-12 mb-2 lg:col-4 lg:mb-0">
                                            <label for="courrierId">Cédula del transportista</label>
                                            <InputText v-model="this.courrier.id" placeholder="Id. del transportista" id="courrierId" type="text" :class="{'p-invalid': this.courrierIdentificationError}" @change="onResetFieldError($event)" :disabled="this.guide.id !== null" />
                                            <small class="p-invalid" v-if="this.courrierIdentificationError">La cédula debe tener 10 digitos</small>
                                        </div>
                                        <div class="col-12 mb-2 lg:col-4 lg:mb-0">
                                            <label for="vehicleId">Placa del Vehículo</label>
                                            <InputText v-model="this.courrier.vehicleId" placeholder="Placa del vehículo" id="vehicleId" type="text" :class="{'p-invalid': this.vehicleIdentificationError}" @change="onResetFieldError($event)" :disabled="this.guide.id !== null" />
                                            <small class="p-invalid" v-if="this.vehicleIdentificationError">Ingrese la placa del vehículo</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid formgrid" style="margin-top: 20px;">
                    <div class="col-12">
                        <div class="card">
                            <h5 style="margin-bottom: 25px;"><b>Lugar y fecha de partida</b></h5>
                            <div class="p-fluid formgrid grid">
                                <div class="field col-12 md:col-12">
                                    <div class="grid formgrid" style="width: 100%;">
                                        <div class="col-12 mb-2 lg:col-4 lg:mb-0">
                                            <label for="departureAddress">Dirección de Salida</label>
                                            <InputText v-model="this.guide.departureAddress" placeholder="Dirección" id="departureAddress" type="text" :class="{'p-invalid': this.departureAddressError}" @change="onResetFieldError($event)" :disabled="this.guide.id !== null" />
                                            <small class="p-invalid" v-if="this.departureAddressError">Ingrese la dirección de salida del vehículo</small>
                                        </div>
                                        <div class="col-12 mb-2 lg:col-4 lg:mb-0">
                                            <label for="startDeliveryDate">Fecha de salida</label>
                                            <Calendar v-model="this.guide.startDeliveryDate" placeholder="dd/mm/yyyy" :maxDate="this.guide.endDeliveryDate" id="startDeliveryDate" :class="{'p-invalid': this.startDeliveryDateError}" @change="onResetFieldError($event)" :disabled="this.guide.id !== null"/>
                                            <small class="p-invalid" v-if="this.startDeliveryDateError">Ingrese fecha de salida del vehículo</small>
                                        </div>
                                        <div class="col-12 mb-2 lg:col-4 lg:mb-0">
                                            <label for="endDeliveryDate">Fecha de llegada</label>
                                            <Calendar v-model="this.guide.endDeliveryDate" placeholder="dd/mm/yyyy" :minDate="this.guide.startDeliveryDate" id="endDeliveryDate" :class="{'p-invalid': this.endDeliveryDateError}" @change="onResetFieldError($event)" :disabled="this.guide.id !== null" />
                                            <small class="p-invalid" v-if="this.endDeliveryDateError">Ingrese fecha de llegada del vehículo</small>
                                        </div>
                                    </div>
                                    <div class="grid formgrid col-12 mb-2 lg:col-8 lg:mb-0 md:col-12 sm:col-12" v-if="this.guide.id === null || this.guide.observations" style="margin-top: 1rem;">
                                        <label for="endDeliveryDate">Observaciones</label>
                                        <Textarea :disabled="this.guide.id !== null" id="observations" rows="2" v-model="this.guide.observations" style="margin-bottom: 10px;"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid formgrid" style="margin-top: 20px;">
                    <div class="col-12">
                        <div class="card">
                            <h5><b>Valores de Guía de Remisión</b></h5>
                            <div class="field col-12 md:col-6">
                                <div style="margin-top: 10px;">
                                    <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                        <label style="margin: 0px;"><b>Secuencial</b></label><br>
                                        <label style="margin: 0px; font-weight: 300;">{{ (this.guide.edoc !== null) ? this.guide.edoc.sequence : "-" }}</label>
                                    </div>
                                    <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                        <label style="margin: 0px;"><b>Fecha Emisión</b></label><br>
                                        <label style="margin: 0px; font-weight: 300;">{{ (this.guide.edoc !== null && this.guide.edoc.sentAt !== null) ? this.guide.edoc.sentAt.toLocaleDateString("es-ES") : "-" }}</label>
                                    </div>
                                    <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                        <label style="margin: 0px;"><b>Fecha Autorización</b></label><br>
                                        <label style="margin: 0px; font-weight: 300;">{{ (this.guide.edoc !== null && this.guide.edoc.authorizedAt !== null) ? this.guide.edoc.authorizedAt.toLocaleDateString("es-ES") : "-" }}</label>
                                    </div>
                                    <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                        <label style="margin: 0px;"><b>Código de acceso</b></label><br>
                                        <label style="margin: 0px; font-weight: 300; max-width: 90%;">{{ (this.guide.edoc !== null) ? this.guide.edoc.accessCode : "-" }}</label>
                                    </div>
                                    <div class="col-12 mb-2 lg:col-12 lg:mb-0" v-if="this.guide.edoc !== null && this.guide.edoc.nulled === true && this.guide.edoc.received === true">
                                        <h5 style="margin: 20px 0px 0px 0px;"><b>Esta guía de remisión debe ser anulada en el portal del SRI</b></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="field col-12 md:col-12 saleFormButtonContainer" style="margin-top: 15px;">
                                <Button label="Limpiar" v-show="showClearButton" class="customButton p-button-raised p-button-danger mr-2 mb-2" @click="onClearFields"/>
                                <Button label="Anular" v-show="showDeleteButton" class="customButton p-button-raised p-button-danger mr-2 mb-2" @click="displayDeleteguideConfirmation(true)"/>
                                <div style="margin-left: auto;"></div>
                                <Button label="Guardar" v-show="showSaveButton" :loading="loadingSaveButton" class="customButton p-button-raised p-button-success mr-2 mb-2" @click="saveGuide()"/>
                                <Button label="Generar" v-show="showCreateEDOCButton" :loading="loadingCreateEDOCButton" class="customButton p-button-raised p-button-success mr-2 mb-2" @click="attemptToCreateRemisionGuide()"/>
                                <Menu ref="menu" :model="downloadOptions" :popup="true" style="width: auto;">
                                    <template #item="{item}">
                                        <Button type="button" :label="item.label" :icon="item.icon" class="menuButton mr-2 mb-2" :loading="loadingDownloadGuideButton" @click="downloadGuide(item.name)"/>
                                    </template>
                                </Menu>
                                <Button type="button" label="Descargar" icon="pi pi-angle-down" v-show="showDownloadGuideButton" class="customButton p-button-raised p-button-success mr-2 mb-2" @click="toggleDownloadMenu"/>
                            </div>
                        </div>
                    </div>
                </div>

                <Dialog header="Advertencia" v-model:visible="showSubscriptionDialog" :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" :modal="true">
                    <p class="line-height-3 m-0">
                        {{ this.error.title }}<br>
                        {{ this.error.mensaje }}<br>
                        {{ this.error.informacionAdicional }}
                    </p>
                    <template #footer>
                        <Button label="Cerrar" @click="closeSubscriptionDialog" class="p-button-secondary mr-2 mb-2"/>
                        <Button label="Ver suscripción" @click="redirectToSubscription" class="p-button-info mr-2 mb-2"/>
                    </template>
                </Dialog>

                <Dialog header="Confirmación" v-model:visible="showDeleteguideConfirmation" :style="{width: '350px'}" :modal="true">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                        <span>¿Está seguro de anular esta guía de remisión?</span>
                    </div>
                    <template #footer>
                        <Button label="No" @click="displayDeleteguideConfirmation(false)" class="p-button-secondary mr-2 mb-2"/>
                        <Button label="Sí" :loading="loadingDeleteButton" @click="deleteGuide()" class="p-button-warning mr-2 mb-2" autofocus />
                    </template>
                </Dialog>

                <Dialog v-model:visible="showProductDialog" :style="{'max-width': '600px', width: '90%'}" header="Buscar Producto" :modal="true" class="p-fluid">
                    <AutoComplete autofocus placeholder="Buscar por nombre..." id="dd" :dropdown="false" :multiple="false" v-model="searchedProduct" @complete="onProductFilterChanged($event)" inputStyle="width: 100%;" style="width: 100%; margin-top: 5px;" :selectOnFocus="true"/>
                    <DataTable :value="this.productOptions" v-model:selection="selectedProduct" selectionMode="single" @rowSelect="onProductSelected" :scrollable="true" scrollHeight="400px" scrollDirection="both" class="mt-3 editable-cells-table">
                        <Column field="code" header="Cód." :style="{width: '25%','min-width':'70px'}"></Column>
                        <Column field="name" header="Nombre." :style="{width: '50%','min-width':'100px'}"></Column>
                        <Column field="price" header="Precio" :style="{width: '10%','min-width':'70px'}">
                            <template #body="slotProps">
                                ${{ slotProps.data.price }}
                            </template>
                        </Column>
                    </DataTable>
                    <Divider layout="horizontal" align="center">
                            <b>O agregue un producto manualmente</b>
						</Divider>
                    <div class="grid formgrid">
                        <div class="col-12 mb-2 lg:col-4 lg:mb-0">
                            <InputText ref="productAmount" type="number" v-model="this.guideItem.amount" placeholder="Cantidad" style="margin-top: 5px;"/>
                        </div>
                        <div class="col-12 mb-2 lg:col-8 lg:mb-0">
                            <InputText ref="productDescription" type="text" v-model="this.guideItem.description" placeholder="Descripción" style="margin-top: 5px;"/>
                        </div>
                    </div>
                    <template #footer>
                        <Button label="Agregar" class="p-button-success mr-2 mb-2" @click="onAddProduct"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="showDestinationDialog" :style="{'max-width': '450px', width: '90%'}" header="Agregar Destino" :modal="true" class="p-fluid">
                    <div class="grid formgrid">
                        <div class="col-12 mb-2 lg:col-12 lg:mb-0" style="margin-bottom: 1rem !important;">
                            <h5 style="margin: 2px 0px 0px 0px;">Nombre Completo del Receptor</h5>
                            <small style="color: gray; font-style: italic;">Busque un cliente existente con el nombre o razón social</small>
                            <AutoComplete autofocus placeholder="Nombres o Razón social" v-model="this.destination" optionLabel="receiverName" field="receiverName" :suggestions="this.destinationSuggestions" @complete="searchDestination($event)">
                                <template #option="slotProps">
                                    <div>{{ slotProps.option.receiverName + " - " + slotProps.option.receiverIdentification }}</div>
                                </template>
                            </AutoComplete>    
                        </div>
                        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                            <h5 style="margin-bottom: 2px;">Céd/RUC/Pas del Receptor</h5>
                            <InputText type="text" v-model="this.destination.receiverIdentification" placeholder="Identificación"  style="margin-bottom: 15px;"/>
                        </div>
                        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                            <h5 style="margin-bottom: 2px;">Dirección del Receptor</h5>
                            <InputText type="text" v-model="this.destination.address" placeholder="Dirección"  style="margin-bottom: 15px;"/>
                        </div>
                        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                            <h5 style="margin-bottom: 2px;">Motivo de traslado</h5>
                            <InputText type="text" v-model="this.destination.reason" placeholder="Motivo"  style="margin-bottom: 15px;"/>
                        </div>
                    </div>
                    <template #footer>
                        <Button label="Agregar" class="p-button-success mr-2 mb-2" @click="onAddDestination"/>
                    </template>
                </Dialog>
			</div>
		</div>
    </div>
</template>

<script>

import download from 'downloadjs'
import httpRequest from '../../service/httpRequest';
import Sale from '../../models/sale';
import Product from '../../models/product';
import RemisionGuide from '../../models/remisionGuide';
import RemisionGuideItem from '../../models/remisionGuideItem';
import RemisionGuideDestination from '../../models/remisionGuideDestination';

export default {
	data() {
		return {
            guide: new RemisionGuide(),
            sale: new Sale(),
            guideItem: new RemisionGuideItem(),
            destination: new RemisionGuideDestination(),
            destinationSuggestions: [],
            courrier: {name: "", id: null, vehicleId: null},
            courrierSuggestions: [],
            searchedProduct: null,
            productOptions: [],
            selectedProduct: null,

            // Show
            // showNewButton: false,
            showProductDialog: false,
            showDestinationDialog: false,
            showSubscriptionDialog: false,
            showClearButton: false,
            showSaveButton: false,
            showCreateEDOCButton: false,
            showDeleteButton: false,
            showDeleteguideConfirmation: false,
            showDownloadGuideButton: false,

            // Errors
            cardBackgroundColor: 'white',
            productError: false,
            destinationError: false,
            courrierIdentificationError: false,
            courrierNameError: false,
            vehicleIdentificationError: false,
            departureAddressError: false,
            startDeliveryDateError: false,
            endDeliveryDateError: false,
            error: {},

            // Loading
            loadingSaveButton: false,
            loadingCreateEDOCButton: false,
            loadingDeleteButton: false,
            loadingDownloadButton: false,
            loadingDownloadGuideButton: false,

            // Download
            downloadOptions: [
                {
                    label: 'PDF',
                    name: 'pdf',
                    icon: 'pi pi-file-pdf'
                },
                {
                    label: 'XML',
                    name: 'xml',
                    icon: 'pi pi-file-excel'
                },
            ],
		}
	},
    async created() {
        window.scrollTo(0, 0);

        // Get guide
        let guideId = this.$route.query.id;
        if (guideId !== null && guideId !== undefined) {
            let response = await httpRequest.getGuide(guideId);
            if (response.status == 200) {
                this.sale = new Sale(response.data.sale);
                this.guide = new RemisionGuide(response.data);
                this.courrier = {name: this.guide.courrierName, id: this.guide.courrierIdentification, vehicleId: this.guide.vehicleIdentification};
                this.setupUI();
            }
        } else {
            // Get sale
            let saleId = this.$route.query.saleId;
            if (saleId !== null && saleId !== undefined) {
                let response = await httpRequest.getSale(saleId);
                if (response.status == 200) {
                    this.sale = new Sale(response.data);
                    this.parseItems(this.sale.items);
                    this.setupUI();
                }
            } else { this.setupUI(); }
        }
    },
	methods: {
        setupUI() {
            this.showNewButton = this.guide.id !== null;
            this.showClearButton = this.guide.id === null;
            this.showDeleteButton = this.showDownloadGuideButton = this.guide.id !== null  && this.guide.edoc !== null && this.guide.edoc.nulled === false;
            this.showSaveButton = this.guide.id === null;
            this.showCreateEDOCButton = this.guide.id !== null && this.guide.edoc === null;
            this.cardBackgroundColor = (this.guide.edoc !== null && this.guide.edoc.nulled === true) ? "#ffc9c9" : "white";
        },
        parseItems(data) {
            var items = [];
            for (var index in data) {
                items.push({amount: data[index].amount, description: data[index].product}) }
            this.guide.items = items.map(item => new RemisionGuideItem(item));
        },
        async onProductFilterChanged(event) {
            let filter = event.query;
            if (filter.length > 1) {
                const result = await httpRequest.getProducts(filter);
                if (result.status === 200) {
                    this.productOptions = result.data.map(product => new Product(product));
                } else { this.productOptions = [] }
            }
        },
        onProductSelected(data) {
            let product = new Product(data.data);
            let object = {
                id: product.id,
                description: product.name,
                amount: 1
            }
            
            this.guide.addItem(new RemisionGuideItem(object));
            
            this.selectedProduct = null;
            this.showProductDialog = false;
            this.productError = false;
        },

        // Courrier
        checkForm() {
            var error = null;
            
            this.productError = false;
            this.destinationError = false;
            this.courrierNameError = false;
            this.courrierIdentificationError = false;
            this.vehicleIdentificationError = false;
            this.departureAddressError = false;
            this.startDeliveryDateError = false;
            this.endDeliveryDateError = false;

            if (this.guide.items === null || this.guide.items.length === 0) {
				this.productError = true;
                if (error === null) { error = "Ingrese al menos un producto" }
			}

            if (this.guide.destinations === null || this.guide.destinations.length === 0) {
				this.destinationError = true;
                if (error === null) { error = "Ingrese un destino" }
			}

			if (this.courrier.name === null || this.courrier.name === undefined || this.courrier.name === "") {
				this.courrierNameError = true;
                if (error === null) { error = "Ingrese el nombre completo del transportista" }
			}

            if (this.courrier.id === null || this.courrier.id === undefined || this.courrier.id === "" || this.courrier.id.length != 10) {
				this.courrierIdentificationError = true;
                if (error === null) { error = "Ingrese la cédula del transportista, debe contener 10 digitos" }
			}

            if (this.courrier.vehicleId === null || this.courrier.vehicleId === undefined || this.courrier.vehicleId === "") {
				this.vehicleIdentificationError = true;
                if (error === null) { error = "Ingrese la placa del vehículo" }
			}

            if (this.guide.departureAddress === null || this.guide.departureAddress === undefined || this.guide.departureAddress === "") {
				this.departureAddressError = true;
                if (error === null) { error = "Ingrese la dirección de salida" }
			}

            if (this.guide.startDeliveryDate === null || this.guide.startDeliveryDate === undefined || this.guide.startDeliveryDate === "") {
				this.startDeliveryDateError = true;
                if (error === null) { error = "Ingrese la fecha de salida" }
			}

            if (this.guide.endDeliveryDate === null || this.guide.endDeliveryDate === undefined || this.guide.endDeliveryDate === "") {
				this.endDeliveryDateError = true;
                if (error === null) { error = "Ingrese la fecha de llegada" }                
			}

			return error;
		},
        onResetFieldError(sender) {
            if (sender.target.id === "courrierName") { this.courrierNameError = false }
            else if (sender.target.id === "courrierId") { this.courrierIdentificationError = false }
            else if (sender.target.id === "vehicleId") { this.vehicleIdentificationError = false }
            else if (sender.target.id === "departureAddress") { this.departureAddressError = false }
            else if (sender.target.id === "startDeliveryDate") { this.startDeliveryDateError = false }
            else if (sender.target.id === "endDeliveryDate") { this.endDeliveryDateError = false }
        },
        async searchCourrier(event) {
            const query = event.query.trim();
            this.courrier = {name: event.query, id: null, vehicleId: null}

            if (!query.length) {
                this.courrierSuggestions = [];
            } else {
                let response = await httpRequest.searchGuideCourrier(query.toLowerCase());
                if (response.status === 200 && response.data !== undefined && response.data !== null) {
                    this.courrierSuggestions = [];
                    response.data.map(d => { this.courrierSuggestions.push({name: d.name, id: d.id, vehicleId: d.vehicleId}) })
                } else {
                    this.$toast.add({severity:'error', summary: 'Error', detail: "Error realizando busqueda", life: 3000});
                }
            }
        },

        // Product
        displayProductDialog() {
            this.showProductDialog = true;
        },
        onAddProduct() {
            this.guide.addItem(new RemisionGuideItem(this.guideItem));
            this.guideItem = new RemisionGuideItem();
            this.showProductDialog = false;
            this.productError = false;
            
        },
        onItemChanged(data) {
            this.guide.addItem(data)
        },
        onItemDeleted(item) {
            this.guide.deleteItem(item.data);
        },

        // Destination
        displayDestinationDialog() {
            this.showDestinationDialog = true;
        },
        onAddDestination() {
            const error = this.destination.isComplete();
            if (error !== null) {
                this.$toast.add({severity:'error', summary: 'Formulario incompleto', detail: error, life: 5000});
            } else {    
                this.guide.addDestination(new RemisionGuideDestination(this.destination));
                this.destination = new RemisionGuideDestination();
                this.showDestinationDialog = false;
                this.destinationError = false;
            }
        },
        onEditDestination(destination) {
            this.destination = destination.data;
            this.showDestinationDialog = true;
        },
        onDestintationDeleted(destination) {
            this.guide.deleteDestination(destination.data);
        },
        async searchDestination(event) {
            this.destination = new RemisionGuideDestination()
            this.destination.receiverName = event.query;

            const query = event.query.trim();
            if (!query.length) {
                this.destinationSuggestions = [];
            } else {
                let response = await httpRequest.searchGuideDestination(query.toLowerCase());
                if (response.status === 200 && response.data !== undefined && response.data !== null) {
                    this.destinationSuggestions = [];
                    response.data.map(d => { this.destinationSuggestions.push(new RemisionGuideDestination(d)) })
                } else {
                    this.$toast.add({severity:'error', summary: 'Error', detail: "Error realizando busqueda", life: 3000});
                }
            }
        },

        // Remision guide
        toggleDownloadMenu(event) {
            this.$refs.menu.toggle(event);
        },
        onClearFields() {
            this.guide = new RemisionGuide();
        },
        closeSubscriptionDialog() {
            this.showSubscriptionDialog = false;
        },
        redirectToSubscription() {
            this.showSubscriptionDialog = false;
            this.$router.push('/dashboard/general/suscripcion');
        },
        async saveGuide() {
            const error = this.checkForm();
            if (error !== null) {
                this.$toast.add({severity:'error', summary: 'Formulario incompleto', detail: error, life: 5000});
            } else {
                // Enable loading mode
                this.loadingSaveButton = true;

                // Attaching courrier to guide data
                this.guide.courrierName = this.courrier.name;
                this.guide.courrierIdentification = this.courrier.id;
                this.guide.vehicleIdentification = this.courrier.vehicleId;

                // Send request
                this.guide.saleId = this.sale.id;
                let response = await httpRequest.saveGuide(this.guide);

                // Disable loading mode
                this.loadingSaveButton = false;

                if (response.data !== undefined && response.data !== null) {
                    this.guide = new RemisionGuide(response.data);
                }

                if (response.status === 200) {
                    history.pushState(
                        {},
                        null,
                        this.$route.path + '?id=' + this.guide.id + '&saleId=' + this.sale.id
                    )
                    this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Su guía de remisión ha sido generada y reportada al SRI.', life: 3000});
                } else if (response.status === 802) { 
                    this.error.title = (response.error === "NO_DOC") ? "Documentos electrónicos agotados" : "Suscripción no válida";
                    this.error.mensaje = "Su guía de remisión ha sido guardada por el momento. Para reportar su guía al SRI por favor renueve su suscripción."
                    this.showSubscriptionDialog = true;
                    
                } else {
                    this.$toast.add({severity:'error', summary: 'Error interno', detail: 'Hemos detectado un error, por favor intente nuevamente.', life: 3000});
                }

                this.setupUI()
            }
        },
        async attemptToCreateRemisionGuide() {
            // Enable loading mode
            this.loadingCreateEDOCButton = true;

            // Send request
            let response = await httpRequest.attemptToCreateRemisionGuide(this.guide.id, this.sale.id);

            // Disable loading mode
            this.loadingCreateEDOCButton = false;

            if (response.data !== undefined && response.data !== null) {
                this.guide = new RemisionGuide(response.data);
            }

            if (response.status === 200) {
                history.pushState(
                    {},
                    null,
                    this.$route.path + '?id=' + this.guide.id + '&saleId=' + this.sale.id
                )
                this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Su guía de remisión ha sido generada y reportada al SRI.', life: 3000});
            } else if (response.status === 802) { 
                this.error.title = (response.error === "NO_DOC") ? "Documentos electrónicos agotados" : "Suscripción no válida";
                this.error.mensaje = "Su guía de remisión ha sido guardada por el momento. Para reportar su guía al SRI por favor renueve su suscripción."
                this.showSubscriptionDialog = true;
            } else {
                this.$toast.add({severity:'error', summary: 'Error interno', detail: 'Hemos detectado un error, por favor intente nuevamente.', life: 3000});
            }

            this.setupUI();
        },
        async deleteGuide() {
            this.loadingDeleteButton = true;
            let response = await httpRequest.deleteGuide(this.guide.id);
            this.showDeleteguideConfirmation = this.loadingDeleteButton = false;
            if (response.status === 200) {
                this.guide = new RemisionGuide(response.data);
                this.setupUI();
                this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Su guía de remisión ha sido anulada, por favor asegúrese de anular este documento en el portal del SRI.', life: 6000});
            } else {
                this.$toast.add({severity:'error', summary: 'Error interno', detail: 'Hemos detectado un error, por favor intente nuevamente.', life: 3000});
            }
        },
        async downloadGuide(type) {
            if (type === 'pdf') {
                this.loadingDownloadGuideButton = true;
                const response = await httpRequest.downloadGuide(this.guide.id, type);
                download(response, "guia_remision_" + this.guide.edoc.serial + ".pdf", "application/pdf");
                this.loadingDownloadGuideButton = false;
            } else if (type === 'xml') {
                this.loadingDownloadGuideButton = true;
                const response = await httpRequest.downloadGuide(this.guide.id, type);
                download(response, "guia_remision_" + this.guide.edoc.serial + ".xml", "application/xml");
                this.loadingDownloadGuideButton = false;
            }
        },
        displayDeleteguideConfirmation(show) {
            this.showDeleteguideConfirmation = show;
        },
    }
}
</script>

<style scoped lang="scss">
    .menuButton {
        width: 100%;
        background-color: white;
        border: 0px;
        color: darkgrey;
    }
    .menuButton:hover {
        background-color: gray;
    }

    @media screen and (max-width: 769px) {
        .number-textfield {
            width: 150px;
            height: 30px;
            font-size: 13px;;
        }
        .guideReceiptContainer {
            width: 100%; 
            display: block;
        }
        .receiptContainer {
            width: 100%;
            margin-right: 0px;
        }
        .receiptSerialContainer {
            width: 100%;
        }
        .saleFormButtonContainer {
            display: block;
        }
        .paymentContainer {
            display: initial;
        }
        .paymentContainer > div > h5 {
            margin: 10px 0 5px 0; 
        }
        .paymentSubcontainer {
            display: block;
        }
        .collectButton {
            margin-top: 10px;
            padding: 0px;
            width: 100%;
        }
        .customButton {
            width: 100%;
        }
    }
    @media screen and (min-width: 769px) {
        .number-textfield {
            margin: 0px 0px 10px auto;
            width: 100px;
            height: 30px;
            font-size: 15px;

        }
        .guideReceiptContainer {
            width: 100%; 
            display: flex;
        }
        .receiptContainer {
            width: 60%;
            margin-right: 20px;
        }
        .receiptSerialContainer {
            width: 40%;
        }
        .saleFormButtonContainer {
            display: flex;
        }
        .saleInformationContainer {
            padding-left: 50px;
        }
        .paymentContainer {
            display: flex;
            // height: 6rem;
        }
        .paymentContainer > div > h5 {
            margin-bottom: 5px; 
        }
        .paymentSubcontainer {
            display: flex;
        }
        .collectButton {
            margin-left: 10px;
        }
        .customButton {
            width: auto;
        }
    }
</style>